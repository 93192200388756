import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilClock,
  cilMoon,
  cilSettings,
  cilUser,
  cilPlus,
  cilSearch,
  cilChevronTop,
  cilChevronBottom,
  cilSync,
  cilChart,
  cilEnvelopeClosed,
  cil3d,
  cilClipboard,
  cilMap,
  cilDollar,
  cilNotes,
  cilHistory,
  cilNewspaper,
  cilArrowThickBottom,
  cilArrowThickTop,
  cilArrowThickLeft,
  cilFile,
  cilDescription,
  cilExternalLink,
  cilBell,
  cilCash,
  cilChatBubble,
  cilCommentBubble,
  cilTrash,
  cilOptions,
  cilLocationPin,
  cilCalendar,
  cilPeople,
  cilBarcode,
  cilGroup,
  cilBook,
  cilFeaturedPlaylist,
  cilSad,
  cilThumbDown,
  cilMoodBad,
  cilCloudDownload,
  cilCart,
  cilTruck,
  cilInfo,
  cilLockLocked,
  cilAccountLogout,
  cilMobile,
  cilBuilding,
  cilExcerpt,
  cilPrint,
  cilMagnifyingGlass,
  cilMoney,
  cilPen
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilClock,
  cilSettings,
  cilUser,
  cilPlus,
  cilSearch,
  cilChevronTop,
  cilChevronBottom,
  cilSync,
  cilChart,
  cilEnvelopeClosed,
  cil3d,
  cilClipboard,
  cilMap,
  cilDollar,
  cilNotes,
  cilHistory,
  cilNewspaper,
  cilArrowThickBottom,
  cilArrowThickTop,
  cilArrowThickLeft,
  cilFile,
  cilDescription,
  cilExternalLink,
  cilBell,
  cilCash,
  cilChatBubble,
  cilCommentBubble,
  cilTrash,
  cilOptions,
  cilLocationPin,
  cilCalendar,
  cilPeople,
  cilBarcode,
  cilGroup,
  cilBook,
  cilFeaturedPlaylist,
  cilSad,
  cilThumbDown,
  cilMoodBad,
  cilCloudDownload,
  cilCart,
  cilTruck,
  cilInfo,
  cilLockLocked,
  cilAccountLogout,
  cilMobile,
  cilBuilding,
  cilExcerpt,
  cilPrint,
  cilMagnifyingGlass,
  cilMoney,
  cilPen
})
